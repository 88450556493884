import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";

export default function FormUsersGitiho() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const defaultdetail = {
        author_id: user.data.id,
        user_id: 0,
        status: 1,
    }
    useEffect(
        function () {
            // console.log(checkLeave);
            console.log(id);

            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "users_gitiho/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                const dataif = res.data.data
                                setDetail(dataif);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            user_id: Yup.string().trim().nullable().required('Chọn nhân sự'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'users_gitiho/' + id, values, user.config).then(res => {
                    setLoading(false)
                    if (res.data.status === "success") {
                        resetForm();
                        setAutoRefresh(!autoRefresh)
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                })
            } else {
                Axios.post(URL_LIST_ALL + 'users_gitiho', values, user.config).then(res => {
                    setLoading(false)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                })
            }
        }
    })

    return (
        <AkkhorLayout idrole="users_gitiho">
            <div className="card height-auto">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title w-100 d-flex justify-content-between">
                            <h4>
                                {id ? "Cập nhật tài khoản Gitiho" : "Thêm mới tài khoản Gitiho"}
                            </h4>
                        </div>
                    </div>
                    <form className="new-added-form row" method="post" onSubmit={formik.handleSubmit}>
                        <div className="col-xl-3 col-lg-6 col-12 form-group">
                            <label>Chọn nhân sự <span className="text-danger">*</span></label>
                            <SearchBoxModal apiname={"users"} placeholder="Chọn nhân sự" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['user_id']} name='user_id' disabled={id > 0 ? true : false}
                                onChange={async (e) => {
                                    await formik.setFieldValue('user_id', e)
                                }}></SearchBoxModal>
                            <AlertErrorForm formik={formik} name="user_id"></AlertErrorForm>
                        </div>
                        {id > 0 ?
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trạng thái: </label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>

                                    <option value="1">Kích hoạt</option>
                                    <option value="0">Ngừng kích hoạt</option>
                                </select>
                            </div>
                            : ''}
                        <div className="col-12 form-group mg-t-8">
                            <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu tài khoản</>}</button>
                            <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    )
}