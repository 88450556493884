
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import { Link } from "react-router-dom";

export default function DanhSachUngVien() {
  const { user } = useSelector((state) => state.auth);
  const [params, setParam] = useState('status=0');
  const [fullname, setFullname] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState("");
  const [filterShow, setFilterShow] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = 'status=0'
    if (fullname !== '') _param += "&fullname=" + fullname
    if (email !== '') _param += "&email=" + email
    // console.log(_param);
    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "sex", label: "Giới tính" },
    { name: "mobile", label: "Điện thoại", width: 150 },
    { name: "email", label: "Email", width: 250 },
    { name: "vitriungtuyen", label: "Vị trí", },
    { name: "tenphongban", label: "Tên phòng ban", },
    { name: "datecreate", label: "Ngày tạo" },
  ];


  return (<AkkhorLayout idrole="users_new">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Danh sách nhân sự
        </li>
      </ul>
    </div>

    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Tên nhân viên </label>
              <input type="text" placeholder="Tìm theo tên" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã nhân viên </label>
              <input type="text" placeholder="Tìm theo mã nhân viên" value={code} onChange={(e) => setCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Email</label>
              <input type="text" placeholder="Tìm theo email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
           
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="users_new" idrole="users_new" params={params} title="Ứng viên" defaultLimit={20} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
